/* Custom layout */
.color-picker .react-colorful {
  padding: 16px;
  border-radius: 0px 12px 12px 12px;
  background: #ffffffaa;
  box-shadow: 0 6px 12px #999;
}

.color-picker .react-colorful__saturation {
  margin: 8px 0 15px 0;
  border-radius: 6px;
  border-bottom: none;
}

.color-picker .react-colorful__hue,
.color-picker .react-colorful__alpha {
  height: 14px;
  border-radius: 5px;
}

.color-picker .react-colorful__hue-pointer,
.color-picker .react-colorful__alpha-pointer {
  width: 20px;
  height: 20px;
}

.color-picker > input {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-family: Poppins;
  color: #999;
}
